.Home-wrap{
    width: 100%;
    min-height: 100%;
    background-color: #f5f5f5;
}
.Home-nav{
    width: 100%;
    height: 70px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
}
.Home-nav-logo{
    width: 94px;
    height: 70px;
    margin-left: 282px;
}
.Home-nav-logo>img{
    width: 94px;
    height: 70px;

}
.Home-nav-list{
    /* width: ; */
    height: 100%;
    display: flex;
    justify-content: flex-start;
    margin-left: 500px;
}
.Home-nav-list>div{
/* padding: 27px; */
min-width: 86px;
height: 70px;
line-height: 70px;
margin-right: 20px;
color: #666666;
font-weight: bold;
cursor: pointer;
}
.Home-nav-list>div:hover{
    color: #2470B8;
    /* border-bottom: 2px solid #2470B8;
    box-sizing: border-box; */
}
.Home-nav-list-1{
    color: #2470B8;
    border-bottom: 2px solid #2470B8;
    box-sizing: border-box;
}
.Home-home{
    width: 100%;
    position: relative;
}
.Home-home>img{
    width: 100%;
}
.Home-home-button{
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 65%;
}
.Home-home-button>button{
    outline: 0;
    width: 300px;
height: 62px;
background: #FFFFFF;
border-radius: 31px;
line-height: 60px;
font-size: 24px;
/* font-family: Microsoft YaHei; */
font-weight: bold;
color: #2470B8;
cursor: pointer;
}
.Home-introduce{
    width: 100%;
    position: relative;
}
.Home-introduce>img{
    width: 100%;
}
.Home-introduce-text{
    width: 70%;
    position: absolute;
    top: 20%;
    margin-left: 15%;
    text-align-last: left;
    color: #666666;
    line-height: 30px;
}
.Home-introduce-example{
    width: 70%;
    height: 500px;
    margin-left: 15%;
    position: absolute;
    top: 40%;
    /* background-color: red; */
    display: flex;
    justify-content: flex-start;
}
.Home-introduce-example-left{
    padding-top: 80px;
    padding-left: 50px;
}
.Home-introduce-example-left>img{
    width: 461px;
    height: 352px;
}
.Home-introduce-example-right{
    margin-left: 100px;
    padding-top: 30px;
    /* background-color: #fff; */
}
.Home-introduce-example-text-li{
text-align: left;
padding: 20px 10px;
}
.Home-introduce-example-text-li:nth-of-type(1),.Home-introduce-example-text-li:nth-of-type(2){
    border-bottom: 1px solid #E6E6E6;

}
.Home-introduce-example-text-li>div:nth-of-type(1){
    font-weight: bold;
    color: #2470B8;
    font-size: 20px;

}
.Home-introduce-example-text-li>div:nth-of-type(2){
    color: #999999;
    margin-top: 10px;
}
.Home-introduce-example-to{
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
    width: 480px;
}
.Home-introduce-example-to>button{
    width: 210px;
height: 54px;
background: #2470B8;
border-radius: 27px;
font-size: 22px;
font-weight: bold;
color: #FFFFFF;
/* outline: 0; */
border: 1px solid transparent;
margin-right: 20px;
cursor: pointer;
}
.Home-partner{
    width: 100%;
    position: relative;
    text-align: left;
}
.Home-partner>img{
    width: 100%;
}
.Home-partner-text{
    position: absolute;
    top: 20%;
    line-height: 30px;
}
.Home-partner-text-1{
    width: 70%;
    margin-left: 15%;
    color: #666666;
}
.Home-partner-text-1>span:nth-of-type(1){
color: #2470B8;
font-weight: bold;
text-decoration: underline;
cursor: pointer;
}
.Home-partner-text-1>span:nth-of-type(2){
    margin-left: 15px;
}
.Home-partner-text-2{
    width: 70%;
    margin-left: 15%;
    margin-top: 15px;
    color: #666666;
}
.Home-partner-text-2>span:nth-of-type(1){
    color: #2470B8;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.Home-partner-text-2>span:nth-of-type(2){
    margin-left: 15px;
}

.Home-civilization{
    width: 100%;
    position: relative;
    text-align: left;
}
.Home-civilization>img{
    width: 100%;
}
.Home-relative{
    width: 100%;
    position: relative;
    text-align: left;
}
.Home-relative>img{
    width: 100%;
}
.Home-relative-address{
    position: absolute;
    top: 20%;
    left: 50%;
}
.Home-relative-address-1>img{
    width: 515px;
    height: 302px;
}
.Home-relative-address-2{
    margin-top: 60px;
    font-weight: bold;
    color: #333333;
    font-size: 24px;

}
.Home-relative-address-3{
    margin-top: 20px;
font-size: 24px;
color: #333333;
}
.Home-relative-address-4{
    color: #999999; margin-top: 60px;
    
}
.Home-relative-address-5{
    color: #999999; margin-top: 20px;
    
}
.Home-bottom{
    width: 100%;
height: 40px;
/* background: #383D41;
line-height: 40px;
text-align: center;
color: #AAAAAC; */
position: relative;
}
.Home-bottom>div:nth-of-type(1){
    width: 100%;
    height: 40px;
    background: #383D41;
    line-height: 40px;
    text-align: center;
    color: #AAAAAC;

}
.Home-bottom>div:nth-of-type(2){
    width: 548px;
    height: 786px;
    position: absolute;
    bottom: 0px;
    left: 12%;
}
.Home-bottom>div:nth-of-type(2)>img{
    width: 548px;
    height: 786px;
    display:block;
}